import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    NgxAuthFirebaseUIModule.forRoot({
      apiKey: "AIzaSyCxB2JFRQ96-p8BieAXfnjqiUS_rHWZJps",
      authDomain: "trinpa-e21be.firebaseapp.com",
      databaseURL: "https://trinpa-e21be.firebaseio.com",
      projectId: "trinpa-e21be",
      storageBucket: "trinpa-e21be.appspot.com",
      messagingSenderId: "98089173043",
      appId: "1:98089173043:web:4aefa86def369479fea346"
    },
      () => 'trinpa_factory',
      {
        enableFirestoreSync: true, // enable/disable autosync users with firestore
        toastMessageOnAuthSuccess: false, // whether to open/show a snackbar message on auth success - default : true
        toastMessageOnAuthError: false, // whether to open/show a snackbar message on auth error - default : true
        authGuardFallbackURL: '/loggedout', // url for unauthenticated users - to use in combination with canActivate feature on a route
        authGuardLoggedInURL: '/loggedin', // url for authenticated users - to use in combination with canActivate feature on a route
        passwordMaxLength: 60, // `min/max` input parameters in components should be within this range.
        passwordMinLength: 8, // Password length min/max in forms independently of each componenet min/max.
        // Same as password but for the name
        nameMaxLength: 50,
        nameMinLength: 2,
        // If set, sign-in/up form is not available until email has been verified.
        // Plus protected routes are still protected even though user is connected.
        guardProtectedRoutesUntilEmailIsVerified: true,
        enableEmailVerification: true, // default: true
      }),

    MatPasswordStrengthModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
